import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class ATVInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="ATV Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Using an all-terrain vehicle (ATV) for hunting and other purposes in Louisiana can be dangerous to riders and those around them so Baton Rouge owners should consider purchasing ATV insurance. Contact us today for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="ATV with Owner and Dog"/>
        <Callout
          title="ATV Insurance"
          content="Using an all-terrain vehicle (ATV) for hunting and other purposes can be dangerous to Baton Rouge riders and those around them, so owners should consider purchasing ATV insurance. In some areas, basic ATV insurance is required by law."
        />
        <div className="site-body page-content">
          <h2>What ATV Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Bodily Injury.</strong> Covers you for bodily injury or death from an accident for which you’re at fault and often covers associated legal defense expenses.</li>
          <li><strong>Property Damage.</strong> Protects you if your ATV accidentally damages another person or business’s property.</li>
          <li><strong>Medical Payments.</strong> Whether you are at fault or not, this usually pays medical expenses of the driver and passengers on an ATV that are in an accident.</li>
          <li><strong>Uninsured Motorists.</strong> This pays for injuries and sometimes certain property damage when involved in an accident with a driver who is uninsured.</li>
          <li><strong>Comprehensive.</strong> This covers ATV damage resulting from flood, fire, vandalism, theft and other covered perils.</li>
          <li><strong>Collision.</strong> Pays for the damage to an ATV when it is hit or hits another object.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="More than one in four people (28 percent) injured in accidents involving all-terrain vehicles (ATVs) in 2015 were children under the age of 16."
            source="Consumer Product Safety Commission"
          />
          <h2>What ATV Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Standard policies will not cover any loss or damages related to organized racing.</li>
          <li>Riding an ATV on highways and roads where it is illegal.</li>
          <li>ATV insurance will only cover individuals on the policy and those who don’t live with you and have permission to use the ATV.</li>
          <li>ATV insurance will not cover the cost of custom add-ons. However, many policies offer an optional policy to cover this.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an ATV Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default ATVInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "atv-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-27.jpg" }) {
      ...mainBg
    }
  }
`;
